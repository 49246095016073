import * as React from "react";

import Layout from "../sections/Layout";
import Seo from "../sections/SEO";
import SimplePage from "../components/SimplePage"

import { navigate } from 'gatsby';
import yn from "yn";

const MAINTENANCE_MODE = yn(process.env.GATSBY_MAINTENANCE_MODE);

const MaintenancePage = () => {
  React.useEffect( redirectToIndex=> {
    if(!MAINTENANCE_MODE) {
      navigate(`/`);
    }
  }, [])

  return (
    <Layout title="Monitor">
      <SimplePage title={"Site Maintenance"} body={"Please come back again soon."} />
    </Layout>
  )
};

export default MaintenancePage;

export const Head = () => (
  <Seo />
);
